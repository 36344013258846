import { makeStyles, Theme } from "@material-ui/core/styles";
import { KitchenOrderClient } from "../../types";

const horizontalPadding = 14;

type DynamicStylesProps = Pick<KitchenOrderClient, 'picture'>;

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 430,
    height: 54,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
    marginBottom: 16,
    borderRadius: 10,
    backgroundColor: "#FFF",
  },

  picture: {
    width: 34,
    height: 34,
    borderRadius: 100,
    backgroundImage: ({ picture }: DynamicStylesProps) => `url(${picture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
  },

  profile: {
    width: 34,
    height: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 34,
    backgroundColor: theme.palette.secondary.main,
  },

  profileIcon: {
    height: 20,
  },

  name: {
    fontSize: 15.5,
    fontWeight: "bold",
    marginLeft: 10,
  },

  nameSector: {
    fontSize: 10,
    marginLeft: 10,
    width: 175,
  },

  texts: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "space-between",
  }
}));

export default useStyles;
