import { Typography } from "@material-ui/core";
import React from "react";
import { ProfileIcon } from "../../assets";
import { KitchenOrderClient } from "../../types";
import useStyles from "./styles";

export const ClientRow: React.FC<KitchenOrderClient> = ({
  picture,
  name,
  sector,
}) => {
  const Styles = useStyles({ picture });

  return (
    <div className={Styles.row}>
      {
        picture
          ? <div className={Styles.picture} />
          : (
            <div className={Styles.profile}>
              <ProfileIcon className={Styles.profileIcon} />
            </div>
          )
      }
      <div className={Styles.texts}>
        <Typography component="span" className={Styles.name}>{name}</Typography>
        <Typography component="span" className={Styles.nameSector}>{sector}</Typography>
      </div>
    </div>
  );
};
